<template>
  <div class="div_login_nuber">
    <div style="padding: 40px">
      <h3 class="h3_title">欢迎使用Keymemo</h3>
      <p class="p_explain">新用户获取验证码，自动登录</p>
      <div class="div_input_body">
        <InputPhone
          ref="phone"
          style="margin-bottom: 20px"
          v-model="newObj.mobile"
        ></InputPhone>
        <div v-if="loginType == 0">
          <InputCode
            ref="code"
            v-model="newObj.sms_secret"
            :getCode="getCode"
          ></InputCode>
          <p class="flex_between">
            <span class="hover_color_t" @click="clickChangeType(1)"
              >密码登录</span
            >
            <span class="hover" @click="clickForget">忘记密码？</span>
          </p>
        </div>
        <div v-else>
          <InputPassworld
            v-model="newObj.passworld"
            ref="passworld"
          ></InputPassworld>
          <div class="flex_between">
            <p>
              <span v-show="warn.show">{{ warn.title }}</span>
              <span class="hover_color_t" @click="clickChangeType(0)"
                >验证码登录</span
              >
            </p>
            <span class="hover" @click="clickForget">忘记密码?</span>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="butsubmit"
        :class="canSubmit() ? 'btnGroupOne' : 'btnGroupNone'"
        @click="clickSubmit"
      >
        登录/注册
      </button>
      <div class="div_bottom">
        <div class="div_agreement flex_center">
          <Radio v-model="privacy"></Radio>
          <p>
            我已阅读并同意遵守 <span class="hover_color_t">服务协议</span> 和
            <span class="hover_color_t">隐私条款</span>
          </p>
        </div>
      </div>
    </div>
    <div class="wx-login" @click="router.replace({ name: 'wxLogin' })">
      <i class="iconfont">&#xe714;</i>
      微信登录
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Radio from "@/components/input/radio.vue";
import InputPhone from "@/components/input/inputPhone.vue";
import InputCode from "@/components/input/inputCode.vue";
import InputPassworld from "@/components/input/inputPassworld.vue";
import md5 from "js-md5";
import { ElMessage } from "element-plus";
import {
  sendValidateCode,
  loginByValidateCode,
  checkMobileExists,
  loginByPassword,
} from "@/api/api.js";
import { useRouter } from "vue-router";
import { setLocalStor, goBackToSource } from "@/utils/index";
const router = useRouter();

let loginType = ref(0); //0验证码登录 1微信登录
let warn = ref({
  show: false,
  title: "",
});

// --表单--
let privacy = ref(false);
let newObj = ref({
  mobile: "",
  sms_key: "",
  sms_secret: "", //验证码
  passworld: "", //密码
});
// --ref---
const newOlder = ref(null);
let phone = ref(null);
let code = ref(null);
let passworld = ref(null);

// 点击获取验证码
function getCode(callback) {
  if (newObj.value.mobile.length == "") {
    phone.value.worring = true;
    ElMessage({
      message: "请输入手机号",
      type: "error",
    });
    return;
  } else if (newObj.value.mobile.length != 11) {
    phone.value.worring = true;
    ElMessage({
      message: "请输入正确的手机号",
      type: "error",
    });
    return;
  }
  sendValidateCode(newObj.value.mobile, 1).then(() => {
    callback(true);
    ElMessage({
      message: "验证码已发送",
      type: "message",
    });
  });
}
// 切换登录类型
function clickChangeType(type) {
  newObj.value.sms_secret = "";
  newObj.value.passworld = "";
  warn.value = {
    show: false,
    title: "",
  };
  loginType.value = type;
}
// 忘记密码
function clickForget() {
  let { href } = router.resolve({
    name: "forgetPassworld",
  });
  window.open(href, "_blank");
}
// 验证
function verification() {
  let isOk = true;
  let worrIng = "";

  // let regExp = /^1[3456789]\d{9}$/;
  // if (!regExp.test(newObj.value.mobile)) {
  //   phone.value.worring = true;
  //   ElMessage({
  //     message: "手机号格式错误",
  //     type: "error",
  //   });
  //   return false;
  // }
  if (loginType.value == 0) {
    // 验证码登录
    if (newObj.value.mobile == "") {
      isOk = false;
      phone.value.worring = true;
      worrIng = "请输入手机号";
    } else if (newObj.value.mobile.length != 11) {
      isOk = false;
      phone.value.worring = true;
      worrIng = "请输入正确的手机号";
    } else if (newObj.value.sms_secret == "") {
      isOk = false;
      code.value.worring = true;
      worrIng = "请输入验证码";
    }
  } else {
    //密码登录
    if (newObj.value.mobile == "") {
      isOk = false;
      phone.value.worring = true;
      worrIng = "请输入手机号";
    } else if (newObj.value.mobile.length != 11) {
      isOk = false;
      phone.value.worring = true;
      worrIng = "请输入正确的手机号";
    } else if (newObj.value.passworld == "") {
      isOk = false;
      passworld.value.worring = true;
      worrIng = "请输入密码";
    }
  }
  if (isOk && privacy.value == false) {
    isOk = false;
    worrIng = "请同意下方服务协议和隐私条款";
  }
  if (!isOk)
    ElMessage({
      message: worrIng,
      type: "error",
    });
  return isOk;
}
function canSubmit() {
  let isok = false;
  if (
    privacy.value &&
    newObj.value.mobile != "" &&
    (newObj.value.sms_secret != "" || newObj.value.passworld != "")
  ) {
    isok = true;
  }
  return isok;
}
// 点击登录注册
function clickSubmit() {
  if (!verification()) return;
  switch (loginType.value) {
    case 0:
      keyLogin();
      break;

    default:
      numberLogin();
      break;
  }
}
// 验证码登录
function keyLogin() {
  // 判断新老用户
  checkMobileExists(newObj.value.mobile).then((resUser) => {
    newOlder.value = resUser.data;
    loginByValidateCode(newObj.value.mobile, newObj.value.sms_secret, 1).then(
      (res) => {
        if (res.code == 200) {
          if (newOlder.value) {
            ElMessage({
              icon: "SuccessFilled",
              type: "success",
              message: "登录成功",
            });
            //直接进入中台
            setTimeout(() => {
              goBackToSource(res.data);
              setLocalStor("userLogin", {
                token: res.data,
                phone: newObj.value.mobile,
              });
            }, 500);
          } else {
            setLocalStor("userLogin", {
              token: res.data,
              phone: newObj.value.mobile,
            });
            ElMessage({
              message: "请将个人信息补充完整",
              type: "message",
            });
            // 补充个人信息
            router.replace({ name: "setMessage" });
          }
        } else {
          ElMessage({
            type: "warning",
            message: "验证码错误",
          });
        }
      }
    );
  });
}
// 账号密码登录
function numberLogin() {
  loginByPassword(newObj.value.mobile, md5(newObj.value.passworld)).then(
    (res) => {
      if (res.code == 200) {
        ElMessage({
          icon: "SuccessFilled",
          type: "success",
          message: "登录成功",
        });
        setTimeout(() => {
          localStorage.clear();
          goBackToSource(res.data);
        }, 1000);
      } else if (res.code == 323) {
        ElMessage({
          type: "warning",
          message: "该账号未设置密码",
        });
      } else if (res.code == 308) {
        ElMessage({
          type: "warning",
          message: "该账号未注册",
        });
      } else if (res.code == 320) {
        ElMessage({
          type: "warning",
          message: "账号或密码不正确",
        });
      }
    }
  );
}
</script>

<style lang='scss' scoped>
.div_login_nuber {
  overflow: hidden;
  text-align: center;
  .p_explain {
    margin: 8px 0px 32px;
  }
  .div_input_body {
    text-align: left;
    .flex_between {
      margin-top: 16px;
      line-height: 1;
      font-size: 14px;
      color: #1f2328;
    }
    .hover_color_t {
      color: #35ba9f !important;
    }
    .btnGroupOne {
      background: #35ba9f !important;
    }
  }
  .butsubmit {
    width: 100%;
    height: 60px;
    margin: 24px 0px 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  .div_bottom {
    .div_agreement {
      p {
        font-size: 14px;
        color: #1f2328;
      }
    }
    .hover_color_t {
      color: #35ba9f !important;
    }
  }
}

.wx-login {
  background: #f7f7f7;
  padding: 18px;
  font-size: 16px;
  line-height: 52px;
  border-radius: 0px 0px 20px 20px;
  cursor: pointer;
}
</style>
