<template>
  <span
    class="span_radio cp_us"
    :class="isChecked ? 'sp_checked' : ''"
    @click="clickRadio"
  >
    <span class="span_dian"></span>
  </span>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from "vue";

const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

let isChecked = ref(false);
onMounted(() => {
  isChecked.value = props.modelValue;
});

function clickRadio() {
  isChecked.value = !isChecked.value;
  emit("update:modelValue", isChecked.value);
}
</script>

<style lang='scss' scoped>
.span_radio {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background: #ffffff;
  border: 1px solid #dcddde;
  background-color: white;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  transition: all $anim-time-fn-easing 0.25s;
  &:hover {
    border: 1px solid #35ba9f;
  }
  .span_dian {
    position: absolute;
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: all ease-in 0.15s;
  }
}

.sp_checked {
  border-color: $subject-color-t;
  background: #35ba9f;
  .span_dian {
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>